<template>
  <div id="datas1">
    <div class="right">
      <div class="title">
        <div class="left_title">
          <div class="img">
            <img src="" alt="" />
          </div>
          <b>课程数据统计</b>
        </div>
        <div class="right_title" @click="getPdf()">导出数据</div>
      </div>
      <div class="right_info" id="pdfDom">
        <table>
          <tr>
            <th>序号</th>
            <th>班级名称</th>
            <th>上课日期</th>
            <th>班级人数</th>
            <th>签到人数</th>
            <th>操作</th>
          </tr>
          <tr v-for="(i, v) in classData" :key="v">
            <td>{{ v + 1 }}</td>
            <td>{{ i.class_name.class_name }}</td>
            <td>{{ i.class_name.create_time }}</td>
            <td>{{ i.class_name.class_count }}</td>
            <td>{{ i.count }}</td>
            <td class="infos">
              <router-link
                :to="{
                  path: '/main/record/answer',
                  query: { id: i.csid, class_id: i.class_id },
                }"
                >答案详情</router-link
              >
              <router-link
                :to="{ path: '/main/record/question', query: { id: i.csid } }"
                >问卷详情</router-link
              >
              <router-link
                :to="{
                  path: '/main/record/classes',
                  query: { id: i.id, csid: i.csid, cid: i.cid },
                }"
                >班级详情</router-link
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
// import { util } from "echarts";
// import { util } from 'echarts';
// import * as util from "@/unit/network"
export default {
  data() {
    return {
      // option: "",
      // timer: "",
      htmlTitle: "wwwwwwww",
    };
  },
  props: ["classData"],
  mounted() {},
  methods: {
    // classInfo(id) {
    //   util.post("/data_class", { class_id: id }, (res) => {
    //     console.log(res);
    //     if (res.code == 1) {
    //       this.classdata = res.data;
    //     }
    //   });
    // },
  },
};
</script>
<style lang="less" scoped>
#datas1 {
  width: 100%;
  height: 100%;
  display: flex;

  .right {
    // display: flex;
    width: 100%;
    .title {
      height: 72px;
      padding: 19px 34px 19px 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cdcdcd;
      .left_title {
        display: flex;
        align-items: center;
        color: #131313;
        font-weight: 500;
        .img {
          width: 26px;
          height: 27px;
          margin-right: 9px;
        }
      }
      .right_title {
        color: #fff;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: #1c5eff;
        border-radius: 5px;
      }
    }
    .right_info {
      height: calc(100% - 72px);
      background: #fff;
      overflow: hidden;
      padding-top: 54px;
      table {
        width: 1360px;
        margin-top: 5px;
        margin-left: 65px;
        border-collapse: collapse;
        border: 1px solid #bebec0;
        font-size: 16px;
        th {
          font-weight: 400;
          background-color: #e9ebee;
          height: 52px;
        }
        td,
        th {
          border-collapse: collapse;
          border: 1px solid #bebec0;
        }
        td {
          height: 80px;
          text-align: center;
          &.infos {
            // display: flex;
            // align-items: center;
            // justify-content: space-around;
            // border: 1px;
            width: 450px;
            a {
              margin: 0 20px;
            }
          }
        }
      }
    }
  }
}
.active {
  background-color: #1c5eff;
}
</style>